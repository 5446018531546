import { ISortByOptions } from './sort/typings';
import { sortBy, sortByKey } from './sort/sort-by';

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Array<T> {
        sortBy(options?: ISortByOptions): Array<T>;
        sortByKey(
            key: T[keyof T] | T[keyof T][],
            options?: ISortByOptions
        ): Array<T>;
    }
}

if (!Array.prototype.sortBy) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.sortBy = function <T>(options?: ISortByOptions): T[] {
        return sortBy(this, options);
    };
}

if (!Array.prototype.sortByKey) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.sortByKey = function <T>(
        key: T[keyof T] | T[keyof T][],
        options?: ISortByOptions
    ): T[] {
        return sortByKey(this, key, options);
    };
}
