/* eslint-disable @typescript-eslint/no-unused-vars */
import { Table } from 'components/lists-tables/table/table';
import { useGetApi } from 'hooks';
import { PropsWithChildren, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CellProps, ColumnWithStrictAccessor } from 'react-table';
import { IPage } from 'typings';
import { sortByKey } from 'utils';
import { RaceProps } from './create-order';

export type OrderProps = {
    id: number;
    timestamp: string;
    phone: string;
    count: number;
    races: RaceForOrderProps[];
    price: number;
    age: string;
    sent: boolean;
    note: string;
    name: string;
    seller_l: boolean;
    seller_m: boolean;
    seller_a: boolean;
    seller_fb: boolean;
};

export type RaceForOrderProps = {
    id: number;
    order_id: number;
    race_id: number;
    count: number;
    free: boolean;
};

export const Tables: IPage = () => {
    const orders = useGetApi<OrderProps[]>('orders');
    const races_for_orders = useGetApi<RaceForOrderProps[]>(
        'orders/races_for_orders'
    );
    const races = useGetApi<RaceProps[]>('races');

    const { aeg, kyllinger, headers } = useMemo(() => {
        const defaultValue: {
            aeg: OrderProps[];
            kyllinger: OrderProps[];
            headers: { id: string; count: number }[];
        } = {
            aeg: [],
            kyllinger: [],
            headers: [],
        };

        if (
            !orders.response?.success ||
            !races_for_orders.response?.success ||
            !races.response?.success
        ) {
            return defaultValue;
        }

        const racesForOrders = races_for_orders.response.result;
        const raceList = races.response.result;

        const headers: Record<string, number> = {};

        return orders.response.result
            .map((order) => {
                const orderHeaders: Record<string, number> = {};
                const freeOrderHeaders: Record<string, number> = {};
                let count = 0;

                racesForOrders
                    .filter(({ order_id }) => order_id === order.id)
                    .forEach((rfo) => {
                        const key = raceList.find(
                            (k) => k.id === rfo.race_id
                        )?.short_name;

                        if (!key) return;

                        if (rfo.free) {
                            if (!freeOrderHeaders[key]) {
                                freeOrderHeaders[key] = rfo.count;
                            } else {
                                freeOrderHeaders[key] += rfo.count;
                            }

                            return;
                        }

                        if (!headers[key]) {
                            headers[key] = rfo.count;
                        } else {
                            headers[key] += rfo.count;
                        }

                        if (!orderHeaders[key]) {
                            orderHeaders[key] = rfo.count;
                        } else {
                            orderHeaders[key] += rfo.count;
                        }

                        count += rfo.count;
                    });

                return {
                    ...orderHeaders,
                    ...order,
                    count,
                    freeRaces: sortByKey(
                        Object.entries(freeOrderHeaders).map(([id, count]) => ({
                            id,
                            count,
                        })),
                        'count',
                        { order: 'descending' }
                    ),
                    sent: order.sent ? 'Ja' : 'Nej',
                };
            })
            .reduce(
                (acc, cur) => {
                    const curRemapped = {
                        ...cur,
                        note: [
                            cur.name,
                            !!cur.freeRaces.length &&
                                `Gratis: ${cur.freeRaces
                                    .map(
                                        (freeRace) =>
                                            `${freeRace.id} = ${freeRace.count}`
                                    )

                                    .join(', ')}`,
                            cur.note,
                        ]
                            .filter(Boolean)
                            .join(', '),
                    };

                    if (curRemapped.age?.trim()) {
                        // @ts-expect-error
                        acc.kyllinger.push(curRemapped);
                    } else {
                        // @ts-expect-error
                        acc.aeg.push(curRemapped);
                    }

                    return acc;
                },
                {
                    ...defaultValue,
                    headers: sortByKey(
                        Object.entries(headers).map(([id, count]) => ({
                            id,
                            count,
                        })),
                        'count',
                        { order: 'descending' }
                    ),
                }
            );
    }, [orders.response, races_for_orders.response, races]);

    const sharedHeaders = useMemo(
        (): ColumnWithStrictAccessor<OrderProps>[] => [
            {
                Header: 'Dato',
                accessor: 'timestamp',
            },
            {
                Header: 'Telefon',
                accessor: 'phone',
            },
            {
                Header: 'Antal',
                accessor: 'count',
            },
            // @ts-expect-error
            ...headers.map(({ id }) => ({ Header: id, accessor: id })),
            {
                Header: 'Pris',
                // @ts-expect-error
                accessor: 'price',
            },
        ],
        [headers]
    );
    const aegOrderColumns = useMemo(
        (): ColumnWithStrictAccessor<OrderProps>[] => [
            ...sharedHeaders,
            {
                Header: 'Sendt',
                accessor: 'sent',
            },
            {
                Header: 'Bemærkning',
                accessor: 'note',
            },
            {
                Header: '',
                accessor: 'id',
                Cell: (
                    props: PropsWithChildren<CellProps<OrderProps, number>>
                ) => <Link to={`/ret/${props.row.original.id}`}>Ret</Link>,
            },
        ],
        [sharedHeaders]
    );

    const kyllingerOrderColumns = useMemo(
        (): ColumnWithStrictAccessor<OrderProps>[] => [
            ...sharedHeaders,
            {
                Header: 'Alder',
                accessor: 'age',
            },
            {
                Header: 'Bemærkning',
                accessor: 'note',
            },
            {
                Header: '',
                accessor: 'id',
                Cell: (
                    props: PropsWithChildren<CellProps<OrderProps, number>>
                ) => <Link to={`/ret/${props.row.original.id}`}>Ret</Link>,
            },
        ],
        [sharedHeaders]
    );

    return (
        <>
            <h1>Æg</h1>
            <Table
                columns={aegOrderColumns}
                data={aeg}
                isLoading={
                    orders.isLoading ||
                    races_for_orders.isLoading ||
                    races.isLoading
                }
            />
            <h1>Kyllinger</h1>
            <Table
                columns={kyllingerOrderColumns}
                data={kyllinger}
                isLoading={
                    orders.isLoading ||
                    races_for_orders.isLoading ||
                    races.isLoading
                }
            />
        </>
    );
};
