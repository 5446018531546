import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { useActions } from 'hooks';

export const stateScope = 'navigation';

interface INavigation {
    isNavigationOpen: boolean;
}

const initialState: INavigation = {
    isNavigationOpen: false,
};

export const slice = createSlice({
    name: stateScope,
    initialState,
    reducers: {
        openNavigation: (state): void => {
            state.isNavigationOpen = true;
        },
        closeNavigation: (state): void => {
            state.isNavigationOpen = false;
        },
        toggleNavigation: (state): void => {
            state.isNavigationOpen = !state.isNavigationOpen;
        },
    },
});

export const { reducer, actions } = slice;

export const { openNavigation, closeNavigation } = actions;

export const getNavigationState = (state: RootState): INavigation =>
    state[stateScope];

export const reduced = { [stateScope]: reducer };

export const useNavigationActions = (): Record<
    keyof typeof actions,
    () => void
> => useActions<keyof typeof actions>(actions);
