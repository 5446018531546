import { api } from 'api';
import { useEffect, useState } from 'react';
import { Response } from 'api/get';

export const useGetApi = <T>(
    url: string
): { isLoading: boolean; response: Response<T> | undefined } => {
    const [response, setResponse] = useState<Response<T>>();

    useEffect(() => {
        api.get<T>({ url, contentType: 'application/json' }).then((response) =>
            setResponse(response)
        );
    }, []);

    return { response, isLoading: !response };
};
