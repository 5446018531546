import { ReactNode } from 'react';
import { ValidLinks } from 'routes';

interface IUnderSites {
    site: ValidLinks;
    name: ReactNode;
}

export interface ISites {
    site: ValidLinks | '';
    name: ReactNode;
    undersider?: IUnderSites[];
    hidden?: boolean;
}

export const sites: ISites[] = [
    {
        site: '',
        name: 'FORSIDE',
    },
    {
        site: 'oversigt',
        name: 'OVERSIGT',
    },
    {
        site: 'opret',
        name: 'OPRET',
    },
];
