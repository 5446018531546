export type Response<T> =
    | {
          status: 200;
          success: true;
          result: T;
      }
    | {
          status: 404;
          success: false;
      };

export async function post<T>({
    url,
    data,
    formatResult,
}: {
    url: string;
    data?: string | Record<string, any>;
    formatResult?: (result: T) => T;
}): Promise<Response<T>> {
    // Default options are marked with *
    const body =
        data !== null && data !== undefined ? JSON.stringify(data) : undefined;

    const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: 'same-origin', // include, *same-origin, omit
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Length': body?.length?.toString() || '0',
            //'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            URL: window.location.href,
        },
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body, // body data type must match "Content-Type" header
    }).catch((e) => {
        console.log(e);
        console.log('Can’t access ' + url + ' response. Blocked by browser?');

        return {
            status: 500,
            json: (): void => {
                return;
            },
        };
    });

    if (process.env.NODE_ENV === 'development') {
        console.log(response);
    }

    const status = response.status as Response<T>['status'];
    const success = status.toString().charAt(0) === '2';

    if (success) {
        // parses JSON response into native JavaScript objects
        // @ts-expect-error
        const result: T = await response.json().catch((err) => {
            if (process.env.NODE_ENV === 'development') {
                console.error(`'${err}' happened, but no big deal!`);
            }

            return null;
        });

        const formattedResult =
            typeof formatResult === 'function' ? formatResult(result) : result;

        return {
            status: status as 200,
            success,
            result: formattedResult,
        };
    }

    return {
        status: status as 404,
        success,
    };
}
