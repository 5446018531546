export function getIntArray(value: string): number[] {
    const array = [];

    const formattedValue = value.toLowerCase();
    const length = formattedValue.length;

    for (let i = 0; i < length; i++) {
        switch (formattedValue.charCodeAt(i)) {
            case 229: // å
                array.push(299);
                break;
            case 248: // ø
                array.push(298);
                break;
            case 230: // æ
                array.push(297);
                break;
            default:
                array.push(formattedValue.charCodeAt(i));
                break;
        }
    }

    return array;
}
