/* eslint-disable @typescript-eslint/no-unused-vars */
import { api } from 'api';
import { Field, FieldArray, Form, Formik } from 'formik';
import { useGetApi } from 'hooks';
import { useMemo, useState } from 'react';
import { IPage } from 'typings';
import { sortByKey } from 'utils';

export interface RaceProps {
    id: number;
    name: string;
    short_name: string;
}

interface AddedRaceProps extends RaceProps {
    count: number;
}

interface FormValues {
    date: string;
    phone: string;
    name: string;
    sellerL: boolean;
    sellerM: boolean;
    sellerA: boolean;
    sellerFB: boolean;
    races: AddedRaceProps[];
    price: number;
    freeRaces: AddedRaceProps[];
    note: string;
    sent: boolean;
    age: string;
}

export const CreateOrder: IPage = () => {
    const [created, setCreated] = useState(false);
    const races = useGetApi<RaceProps[]>('races');

    const sortedRaces = useMemo(() => {
        if (!races.response?.success) return [];

        return sortByKey(races.response.result, 'sorting');
    }, [races.response]);

    const handleSubmit = (values: FormValues) => {
        const races = [
            ...values.races
                .filter(({ count }) => count)
                .map((race) => ({
                    id: 0,
                    order_id: 0,
                    race_id: parseInt(race.id.toString()),
                    count: parseInt(race.count.toString()),
                    free: false,
                })),
            ...values.freeRaces
                .filter(({ count }) => count)
                .map((race) => ({
                    id: 0,
                    order_id: 0,
                    race_id: parseInt(race.id.toString()),
                    count: parseInt(race.count.toString()),
                    free: true,
                })),
        ];

        api.post({
            url: 'order',
            data: {
                id: 0,
                timestamp: values.date,
                name: values.name,
                phone: values.phone,
                price: values.price,
                seller_l: values.sellerL,
                seller_m: values.sellerM,
                seller_a: values.sellerA,
                seller_fb: values.sellerFB,
                age: values.age,
                sent: values.sent,
                note: values.note,
                races,
            },
        }).then((result) => {
            if (!result.success || typeof result.result !== 'number') return;

            setCreated(true);

            setTimeout(() => window.location.reload(), 2000);
        });
    };

    if (created) return <div>Bestillingen er gemt! 🤑</div>;

    if (!races.response?.success) return null;

    return (
        <Formik<FormValues>
            initialValues={{
                date: new Date().toISOString().split('T')[0],
                phone: '',
                name: '',
                sellerL: false,
                sellerM: false,
                sellerA: false,
                sellerFB: false,
                races: [
                    { ...sortedRaces[0], count: 0 },
                    { ...sortedRaces[1], count: 0 },
                    { ...sortedRaces[2], count: 0 },
                ],
                price: 0,
                freeRaces: [],
                note: '',
                sent: false,
                age: '',
            }}
            onSubmit={handleSubmit}
            render={({ values, dirty, handleChange, handleBlur }) => (
                <Form>
                    <h1>Opret bestilling</h1>
                    <label>Dato</label>
                    <Field name="date" type="date" />
                    <br />
                    <label>Telefonnummer</label>
                    <Field name="phone" type="text" />
                    <br />
                    <label>Navn</label>
                    <Field name="name" type="text" />
                    <br />
                    <label>Sælger</label>
                    <Field name="sellerL" type="checkbox" />
                    Louise
                    <br />
                    <Field name="sellerM" type="checkbox" />
                    Maria
                    <br />
                    <Field name="sellerA" type="checkbox" />
                    Andet
                    <br />
                    <Field name="sellerFB" type="checkbox" />
                    Facebook
                    <br />
                    <label>Racer</label>
                    <FieldArray
                        name="races"
                        render={(arrayHelpers) => (
                            <>
                                {values.races.map((race, index) => (
                                    <div key={index}>
                                        <Field
                                            name={`races.${index}.count`}
                                            type="number"
                                        />{' '}
                                        <select
                                            name={`races.${index}.id`}
                                            defaultValue={race.id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            {sortedRaces.map((sortedRace) => (
                                                <option
                                                    key={sortedRace.id}
                                                    value={sortedRace.id}
                                                >
                                                    {sortedRace.name}
                                                </option>
                                            ))}
                                        </select>{' '}
                                        <button
                                            type="button"
                                            onClick={() =>
                                                arrayHelpers.remove(index)
                                            }
                                        >
                                            Slet
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={() => {
                                        const raceToAdd =
                                            sortedRaces.find(
                                                (race) =>
                                                    !values.races.some(
                                                        (addedRace) =>
                                                            addedRace.id ===
                                                            race.id
                                                    )
                                            ) ?? sortedRaces[0];

                                        arrayHelpers.push({
                                            ...raceToAdd,
                                            count: 0,
                                        });
                                    }}
                                >
                                    Tilføj race
                                </button>
                            </>
                        )}
                    />
                    <br />
                    <label>Pris</label>
                    <Field name="price" type="number" />
                    <br />
                    <label>Gratis racer</label>
                    <FieldArray
                        name="freeRaces"
                        render={(arrayHelpers) => (
                            <>
                                {values.freeRaces.map((race, index) => (
                                    <div key={index}>
                                        <Field
                                            name={`freeRaces.${index}.count`}
                                            type="number"
                                        />{' '}
                                        <select
                                            name={`freeRaces.${index}.id`}
                                            defaultValue={race.id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            {sortedRaces.map((sortedRace) => (
                                                <option
                                                    key={sortedRace.id}
                                                    value={sortedRace.id}
                                                >
                                                    {sortedRace.name}
                                                </option>
                                            ))}
                                        </select>{' '}
                                        <button
                                            type="button"
                                            onClick={() =>
                                                arrayHelpers.remove(index)
                                            }
                                        >
                                            Slet
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={() => {
                                        const raceToAdd =
                                            sortedRaces.find(
                                                (race) =>
                                                    race.short_name === 'R'
                                            ) ?? sortedRaces[0];

                                        arrayHelpers.push({
                                            ...raceToAdd,
                                            count: 0,
                                        });
                                    }}
                                >
                                    Tilføj gratis race
                                </button>
                            </>
                        )}
                    />
                    <br />
                    <label>Bemærkning</label>
                    <Field name="note" type="text" />
                    <br />
                    <br />
                    <Field name="sent" type="checkbox" />
                    Sendt
                    <br />
                    <label>Alder</label>
                    <Field name="age" type="text" />
                    <br />
                    <br />
                    <button type="submit" disabled={!dirty}>
                        {values.age.trim()
                            ? 'Opret kylle bestilling'
                            : 'Opret rugeæg bestilling'}
                    </button>
                </Form>
            )}
        />
    );
};
