import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { reduced as navigation } from './navigation';
//import { reduced as tooltips } from 'components/overlays/tooltip';
import { reduced as layout } from 'components/structure/layout/slice';
import { useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        ...navigation,
        //...tooltips,
        ...layout,
    },
});

export type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export const useStore = (): RootState =>
    useSelector((state: RootState): RootState => state);

export const useStoreScope = <Key extends keyof RootState>(
    key: Key
): RootState[Key] =>
    useSelector((state: RootState): RootState[Key] => state[key]);

export const useStoreDispatch = (): AppDispatch => useDispatch<AppDispatch>();
