import { api } from 'api';
import { useCallback, useEffect, useState } from 'react';

export const useIsAuthenticated = (): {
    isAuthenticated: boolean;
    isLoading: boolean;
    checkAuthentication: () => void;
} => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();

    const checkAuthentication = useCallback(() => {
        api.get({
            url: 'authenticate',
            contentType: 'text/markdown',
        })
            .then((result) => {
                setIsAuthenticated(result.status === 200 && result.success);
            })
            .catch(() => {
                setIsAuthenticated(false);
            });
    }, []);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            return setIsAuthenticated(true);
        }

        checkAuthentication();
    }, []);

    return {
        isAuthenticated: isAuthenticated === true,
        isLoading: isAuthenticated === undefined,
        checkAuthentication,
    };
};
