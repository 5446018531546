/* eslint-disable react/prop-types */
import { useClassName } from 'hooks';
import { FC } from 'react';
import styles from './logo.module.scss';

export interface ILogo {
    size: 'small' | 'medium' | 'medium2';
    className?: string;
}

export const Logo: FC<ILogo> = ({ size, className }) => {
    const logoClassName = useClassName(
        styles.logo,
        size === 'small' && styles.small,
        (size === 'medium' || size === 'medium2') && styles.medium,
        className
    );

    if (size === 'small') {
        return (
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 168 168"
                className={logoClassName}
            >
                <circle className={styles.light} cx="84.23" cy="84.25" r="84" />
                <path
                    d="M143.41,52.35c-0.96-0.09-1.67-0.79-2.58-0.98c-0.62-0.14-1.21-0.34-1.83-0.48c-2.51-0.51-2.64-0.43-3.75,1.86
	c-0.89,1.88-2.34,2.96-4.47,2.99c-0.25,0-0.51,0.03-0.81,0.06c-0.22,1.12,0.06,2.18,0.14,3.23c0.42,5.38,1.18,10.74,1.86,16.08
	c0.99,7.65,0.27,15.15-1.97,22.52c-0.59,1.95-1.16,3.92-1.76,5.88c-1.47,4.86-4.6,8.6-8.21,12.02c-4.47,4.2-9.4,7.81-14.1,11.69
	c-4.52,3.72-8.78,7.73-13.17,11.61c-0.64,0.57-1.3,1.09-1.99,1.63c-1.03,0.8-1.91,1.74-2.48,2.94c-1.21,2.55-3.23,4.47-5.05,6.52
	c-0.72,0.8-0.91,1.48-0.62,2.56c1.19,4.49,2.38,8.97,4.07,13.31c0.28,0.74,0.63,1.12,1.46,1.24c1.6,0.24,3.2,0.5,4.82,0.77
	c-2.87,0.3-5.79,0.45-8.74,0.45c-2.24,0-4.45-0.09-6.64-0.27c-0.1-0.15-0.19-0.33-0.27-0.52c0.21-0.33,0.62-0.41,0.97-0.6
	c1.49-0.81,2.05-2.05,1.8-3.77c-0.99-6.6-3.12-12.86-5.58-19.02c-0.1-0.25-0.26-0.47-0.29-0.73c-0.25-1.97-1.43-2.62-3.34-2.88
	c-4.17-0.59-8.2-1.79-12.08-3.48c-3.39-1.46-6.03-3.8-7.84-7.03c-2.66-4.75-5.76-9.22-8.62-13.85c-0.29-0.46-0.67-0.74-1.16-0.97
	c-1.32-0.6-2.6-1.32-3.8-2.13c-1.23-0.79-2.13-1.84-2.64-3.23c-0.19-0.52-0.42-1.01-0.86-1.41c-0.73-0.65-1.07-1.51-1.07-2.48
	c0-0.61-0.22-0.97-0.76-1.23c-2.02-0.97-3.04-2.76-3.86-4.75c0.52-0.56,1.35-0.41,1.95-0.89c-0.95-0.57-1.91-1.11-2.85-1.69
	c-1.08-0.63-1.82-1.56-2.01-2.8c-0.18-1.03-0.75-1.62-1.61-2.11c-2.43-1.45-3.82-3.71-4.64-6.35c-0.48-1.49-0.51-2.97,0.36-4.4
	c0.48-0.79,0.32-1.62-0.19-2.4c-1.19-1.86-2.3-3.75-2.81-5.93c-0.35-1.46-0.18-2.01,1.11-2.8c0.52-0.32,0.56-0.68,0.54-1.19
	c-0.08-1.56-0.17-3.14,0.22-4.68c0.67-2.64,1.99-3.58,4.69-3.29c0.7,0.08,1.4,0.18,2.18,0.29c0.89-2.29,0.99-5.09,4.04-5.82
	c-0.68-1.53-1.32-2.88-1.89-4.23c-0.41-0.97-0.72-1.97-0.91-2.99c-0.25-1.54,0.4-2.34,1.97-2.45c0.96-0.06,1.85,0.21,2.66,0.68
	c3.61,2.12,6.12,5.27,8.46,8.62c3.85,5.52,6.41,11.66,9,17.79c1.11,2.61,2.35,5.14,3.82,7.57c1.32,2.2,3.24,3.29,5.8,3.36
	c6.49,0.14,12.8-0.88,19.02-2.75c5.02-1.53,9.97-3.25,14.85-5.17c3.82-1.51,6.3-4.4,8.4-7.73c2.29-3.64,3.56-7.76,5.15-11.73
	c1.46-3.64,3.11-7.19,5.93-10.02c2.89-2.9,6.41-4.84,10.21-6.27c1.38-0.5,2.8-1,4.27-1.54c-0.41-0.7-1.17-1.37-0.66-2.1
	c0.62-0.89,1.59-0.01,2.38-0.14c0.56-2.04,0.56-2.04,3.42-1.13c0.33-0.52,0.05-1.41,0.72-1.64c0.65-0.22,1,0.51,1.43,0.89
	c1.33-0.33,1.46-0.28,1.91,1.12c0.25,0.81,0.42,1.65,0.65,2.49c0.11-0.03,0.24-0.03,0.3-0.09c0.3-0.37,0.54-0.91,1.13-0.67
	c0.55,0.24,0.46,0.79,0.48,1.27c0,0.38-0.1,0.83,0.25,1.05c1.48,0.88,1.4,2.39,1.48,3.75c0.09,1.48,0.62,2.66,1.62,3.69
	c0.88,0.88,1.61,1.86,2.21,2.93C143.56,51,143.79,51.62,143.41,52.35z"
                />
            </svg>
        );
    }

    if (size === 'medium') {
        return (
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 194 194"
                className={logoClassName}
            >
                <circle className={styles.light} cx="97.23" cy="97.25" r="97" />
                <path
                    d="M97.23,14.25c46.32,0,84,37.68,84,84s-37.68,84-84,84s-84-37.68-84-84S50.91,14.25,97.23,14.25 M97.23,9.25
   c-49.15,0-89,39.85-89,89s39.85,89,89,89s89-39.85,89-89S146.38,9.25,97.23,9.25L97.23,9.25z"
                />
                <path
                    d="M152.21,124.53c-0.33,1.62-0.62,3.23-0.51,4.91c0.2,3.44-2.69,6.75-5.59,7.71c-1.75,0.59-2.44,1.53-2.75,3.1
   c-0.04,0.16-0.08,0.3-0.13,0.46h-6.42c0.65-0.24,1.29-0.38,1.94-0.53c0.47-0.09,0.94-0.22,0.84-0.83c-0.08-0.59-0.01-1.37-0.92-1.4
   c-1.52-0.03-2.62-0.79-3.81-1.66c-1.3-0.96-2.42-2.04-3.54-3.19c-1.17-1.21-1.94-2.77-3.27-3.85c-1.36-1.11-2.04-2.66-2.71-4.19
   c-0.26-0.61-1.17-1.42,0.08-2.19c-0.69-0.2-0.67-0.78-1.01-1.11c0.43-0.57,0.83-1.09,1.23-1.62c0.14-0.2,0.29-0.4,0.09-0.65
   c-0.71-0.9-1.16-2.08-2.56-2.17c-0.26-0.01-0.51-0.13-0.92-0.25c0.45-0.82,1.2-1.15,1.86-1.55c0.41-0.24,0.78-0.46,0.8-0.99
   c0.05-1.61,1.09-2.44,2.4-3.11c3-1.57,5.93-1.7,8.72,0.47c0.14,0.12,0.32,0.18,0.46,0.29c1.13,0.8,1.81,1.9,2.06,3.24
   c0.11,0.62,1.38,2.04,2.03,2.11c3.03,0.3,5.44,1.87,7.79,3.6c1.11,0.82,2.13,1.82,3.72,1.29c0.04-0.01,0.11,0.04,0.2,0.08
   C152.48,123.19,152.34,123.86,152.21,124.53z"
                />
                <path
                    d="M140.76,44.42c-0.79-0.08-1.38-0.66-2.13-0.82c-0.51-0.12-1-0.28-1.52-0.4c-2.08-0.42-2.19-0.36-3.11,1.54
   c-0.74,1.55-1.94,2.45-3.7,2.48c-0.21,0-0.42,0.03-0.67,0.05c-0.18,0.92,0.05,1.81,0.12,2.67c0.34,4.45,0.98,8.89,1.54,13.32
   c0.82,6.34,0.22,12.56-1.63,18.66c-0.49,1.62-0.96,3.25-1.46,4.88c-1.21,4.03-3.81,7.13-6.8,9.96c-3.7,3.48-7.79,6.47-11.69,9.68
   c-3.74,3.08-7.27,6.4-10.91,9.62c-0.53,0.47-1.08,0.91-1.65,1.36c-0.86,0.66-1.58,1.44-2.06,2.44c-1,2.11-2.67,3.7-4.19,5.4
   c-0.59,0.66-0.75,1.23-0.51,2.12c0.99,3.72,1.98,7.43,3.37,11.03c0.24,0.61,0.53,0.92,1.21,1.03c2.56,0.38,5.11,0.82,7.75,1.26
   H86.61c-0.24-0.22-0.45-0.53-0.61-0.91c0.17-0.28,0.51-0.34,0.8-0.5c1.24-0.67,1.7-1.7,1.49-3.12c-0.82-5.47-2.58-10.66-4.62-15.76
   c-0.08-0.21-0.21-0.4-0.24-0.61c-0.21-1.63-1.19-2.17-2.77-2.38c-3.45-0.49-6.8-1.49-10.01-2.89c-2.81-1.21-4.99-3.15-6.5-5.82
   c-2.2-3.94-4.77-7.64-7.14-11.48c-0.24-0.38-0.55-0.62-0.96-0.8c-1.09-0.5-2.15-1.09-3.15-1.77c-1.01-0.66-1.77-1.53-2.19-2.67
   c-0.16-0.43-0.34-0.84-0.71-1.17c-0.61-0.54-0.88-1.25-0.88-2.06c0-0.5-0.18-0.8-0.63-1.01c-1.67-0.8-2.52-2.29-3.2-3.94
   c0.43-0.46,1.12-0.34,1.62-0.74c-0.79-0.47-1.58-0.92-2.36-1.4c-0.9-0.53-1.52-1.29-1.67-2.32c-0.14-0.86-0.62-1.34-1.33-1.75
   c-2.02-1.2-3.16-3.07-3.85-5.26c-0.4-1.24-0.42-2.46,0.3-3.65c0.4-0.66,0.26-1.34-0.16-1.99c-0.99-1.54-1.91-3.11-2.33-4.91
   c-0.29-1.21-0.14-1.66,0.92-2.32c0.43-0.26,0.46-0.57,0.45-0.99c-0.07-1.29-0.14-2.6,0.18-3.87c0.55-2.19,1.65-2.96,3.89-2.73
   c0.58,0.07,1.16,0.14,1.81,0.24c0.74-1.9,0.82-4.22,3.35-4.82c-0.57-1.26-1.09-2.38-1.57-3.5c-0.34-0.8-0.59-1.63-0.75-2.48
   c-0.21-1.28,0.33-1.94,1.63-2.03c0.79-0.05,1.53,0.17,2.2,0.57c2.99,1.75,5.07,4.36,7.01,7.14c3.19,4.57,5.31,9.66,7.46,14.74
   c0.92,2.16,1.95,4.26,3.16,6.27c1.09,1.82,2.69,2.73,4.81,2.78c5.38,0.12,10.61-0.72,15.76-2.28c4.16-1.26,8.26-2.69,12.31-4.28
   c3.16-1.25,5.22-3.65,6.96-6.4c1.9-3.02,2.95-6.43,4.27-9.72c1.21-3.02,2.58-5.96,4.91-8.3c2.4-2.4,5.31-4.01,8.46-5.19
   c1.15-0.42,2.32-0.83,3.54-1.28c-0.34-0.58-0.98-1.13-0.55-1.74c0.51-0.74,1.32-0.01,1.98-0.12c0.46-1.69,0.46-1.69,2.83-0.94
   c0.28-0.43,0.04-1.17,0.59-1.36c0.54-0.18,0.83,0.42,1.19,0.74c1.11-0.28,1.21-0.24,1.58,0.92c0.21,0.67,0.36,1.37,0.54,2.07
   c0.09-0.03,0.2-0.03,0.25-0.08c0.25-0.3,0.45-0.75,0.94-0.55c0.46,0.2,0.38,0.66,0.4,1.05c0,0.32-0.08,0.69,0.21,0.87
   c1.23,0.72,1.16,1.98,1.23,3.11c0.08,1.23,0.51,2.2,1.34,3.06c0.72,0.72,1.33,1.54,1.83,2.42C140.89,43.3,141.08,43.81,140.76,44.42
   z"
                />
                <path
                    d="M53.42,148.51v5.41h8.32v-5.41H59.5v-2.39h7.33v2.39h-2.21v14.36h2.21v2.39H59.5v-2.39h2.23v-6.56h-8.32v6.56h2.26v2.39
	   h-7.31v-2.39h2.16v-14.36h-2.16v-2.39h7.31v2.39H53.42z"
                />
                <path d="M75.26,148.51l4.89,13l4.74-13h-2.21v-2.39h7.3v2.39h-2.29l-6.32,16.95h-2.65l-6.42-16.95h-2.36v-2.39h7.54v2.39H75.26z" />
                <path d="M98.17,148.51v14.36h2.08v2.39H93.2v-2.39h2.07v-14.36H93.2v-2.39h7.05v2.39H98.17z" />
                <path
                    d="M120.39,146.12h6.63v2.39h-2.31v14.36h2.31v2.39h-7.2v-2.39h2.26v-14.36l-6.58,16.95h-0.57l-6.53-16.95v14.36h2.11v2.39
	   h-6.79v-2.39h2.32v-14.36h-2.32v-2.39h6.56l5.04,13.1L120.39,146.12z"
                />
                <path
                    d="M140.44,147.32v-1.31h2.34v5.8h-2.34v-1.32c-0.99-1.49-2.34-2.23-4.06-2.23c-1.05,0-1.85,0.21-2.41,0.63
	   c-0.56,0.42-0.84,1.03-0.84,1.83c0,0.76,0.26,1.34,0.79,1.74c0.53,0.4,1.34,0.74,2.43,1.02l2.25,0.6c1.43,0.38,2.53,0.88,3.29,1.51
	   c0.77,0.63,1.26,1.29,1.5,1.99c0.23,0.7,0.35,1.49,0.35,2.35c0,1.73-0.54,3.11-1.62,4.13c-1.08,1.02-2.53,1.52-4.35,1.52
	   c-1.73,0-3.31-0.63-4.74-1.9v1.59h-2.39v-6.97h2.39v1.69c1.14,2.1,2.63,3.15,4.47,3.15c1.08,0,1.91-0.28,2.5-0.83
	   c0.59-0.55,0.89-1.33,0.89-2.34c0-1.37-0.96-2.32-2.89-2.86l-2.39-0.67c-1.72-0.49-2.99-1.16-3.81-1.99
	   c-0.82-0.84-1.23-1.94-1.23-3.31c0-1.68,0.5-2.99,1.49-3.92c0.99-0.93,2.37-1.39,4.12-1.39
	   C137.7,145.81,139.12,146.31,140.44,147.32z"
                />
                <rect x="22.55" y="140.75" width="149" height="3" />
                <rect x="47.55" y="167.75" width="99" height="3" />
            </svg>
        );
    }

    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 212 212"
            className={logoClassName}
        >
            <circle className={styles.light} cx="106.23" cy="106.25" r="106" />
            <path
                className={styles.color}
                d="M54.76,179.25c-1.29-0.92-2.57-1.89-3.81-2.89c-8.35-6.67-15.34-14.7-20.79-23.87c-0.44-0.74-0.87-1.49-1.3-2.24H183.6
       c-0.42,0.75-0.85,1.5-1.3,2.24c-5.45,9.17-12.45,17.19-20.79,23.87c-1.23,1-2.51,1.97-3.81,2.89H54.76z"
            />
            <path
                d="M106.23,23.25c46.32,0,84,37.68,84,84s-37.68,84-84,84s-84-37.68-84-84S59.91,23.25,106.23,23.25 M106.23,18.25
   c-49.15,0-89,39.85-89,89s39.85,89,89,89s89-39.85,89-89S155.38,18.25,106.23,18.25L106.23,18.25z"
            />
            <path
                d="M161.21,133.53c-0.33,1.62-0.62,3.23-0.51,4.91c0.2,3.44-2.69,6.75-5.59,7.71c-1.75,0.59-2.44,1.53-2.75,3.1
   c-0.04,0.16-0.08,0.3-0.13,0.46h-6.42c0.65-0.24,1.29-0.38,1.94-0.53c0.47-0.09,0.94-0.22,0.84-0.83c-0.08-0.59-0.01-1.37-0.92-1.4
   c-1.52-0.03-2.62-0.79-3.81-1.66c-1.3-0.96-2.42-2.04-3.54-3.19c-1.17-1.21-1.94-2.77-3.27-3.85c-1.36-1.11-2.04-2.66-2.71-4.19
   c-0.26-0.61-1.17-1.42,0.08-2.19c-0.69-0.2-0.67-0.78-1.01-1.11c0.43-0.57,0.83-1.09,1.23-1.62c0.14-0.2,0.29-0.4,0.09-0.65
   c-0.71-0.9-1.16-2.08-2.56-2.17c-0.26-0.01-0.51-0.13-0.92-0.25c0.45-0.82,1.2-1.15,1.86-1.55c0.41-0.24,0.78-0.46,0.8-0.99
   c0.05-1.61,1.09-2.44,2.4-3.11c3-1.57,5.93-1.7,8.72,0.47c0.14,0.12,0.32,0.18,0.46,0.29c1.13,0.8,1.81,1.9,2.06,3.24
   c0.11,0.62,1.38,2.04,2.03,2.11c3.03,0.3,5.44,1.87,7.79,3.6c1.11,0.82,2.13,1.82,3.72,1.29c0.04-0.01,0.11,0.04,0.2,0.08
   C161.48,132.19,161.34,132.86,161.21,133.53z"
            />

            <path
                d="M149.76,53.42c-0.79-0.08-1.38-0.66-2.13-0.82c-0.51-0.12-1-0.28-1.52-0.4c-2.08-0.42-2.19-0.36-3.11,1.54
   c-0.74,1.55-1.94,2.45-3.7,2.48c-0.21,0-0.42,0.03-0.67,0.05c-0.18,0.92,0.05,1.81,0.12,2.67c0.34,4.45,0.98,8.89,1.54,13.32
   c0.82,6.34,0.22,12.56-1.63,18.66c-0.49,1.62-0.96,3.25-1.46,4.88c-1.21,4.03-3.81,7.13-6.8,9.96c-3.7,3.48-7.79,6.47-11.69,9.68
   c-3.74,3.08-7.27,6.4-10.91,9.62c-0.53,0.47-1.08,0.91-1.65,1.36c-0.86,0.66-1.58,1.44-2.06,2.44c-1,2.11-2.67,3.7-4.19,5.4
   c-0.59,0.66-0.75,1.23-0.51,2.12c0.99,3.72,1.98,7.43,3.37,11.03c0.24,0.61,0.53,0.92,1.21,1.03c2.56,0.38,5.11,0.82,7.75,1.26
   H95.61c-0.24-0.22-0.45-0.53-0.61-0.91c0.17-0.28,0.51-0.34,0.8-0.5c1.24-0.67,1.7-1.7,1.49-3.12c-0.82-5.47-2.58-10.66-4.62-15.76
   c-0.08-0.21-0.21-0.4-0.24-0.61c-0.21-1.63-1.19-2.17-2.77-2.38c-3.45-0.49-6.8-1.49-10.01-2.89c-2.81-1.21-4.99-3.15-6.5-5.82
   c-2.2-3.94-4.77-7.64-7.14-11.48c-0.24-0.38-0.55-0.62-0.96-0.8c-1.09-0.5-2.15-1.09-3.15-1.77c-1.01-0.66-1.77-1.53-2.19-2.67
   c-0.16-0.43-0.34-0.84-0.71-1.17c-0.61-0.54-0.88-1.25-0.88-2.06c0-0.5-0.18-0.8-0.63-1.01c-1.67-0.8-2.52-2.29-3.2-3.94
   c0.43-0.46,1.12-0.34,1.62-0.74c-0.79-0.47-1.58-0.92-2.36-1.4c-0.9-0.53-1.52-1.29-1.67-2.32c-0.14-0.86-0.62-1.34-1.33-1.75
   c-2.02-1.2-3.16-3.07-3.85-5.26c-0.4-1.24-0.42-2.46,0.3-3.65c0.4-0.66,0.26-1.34-0.16-1.99c-0.99-1.54-1.91-3.11-2.33-4.91
   c-0.29-1.21-0.14-1.66,0.92-2.32c0.43-0.26,0.46-0.57,0.45-0.99c-0.07-1.29-0.14-2.6,0.18-3.87c0.55-2.19,1.65-2.96,3.89-2.73
   c0.58,0.07,1.16,0.14,1.81,0.24c0.74-1.9,0.82-4.22,3.35-4.82c-0.57-1.26-1.09-2.38-1.57-3.5c-0.34-0.8-0.59-1.63-0.75-2.48
   c-0.21-1.28,0.33-1.94,1.63-2.03c0.79-0.05,1.53,0.17,2.2,0.57c2.99,1.75,5.07,4.36,7.01,7.14c3.19,4.57,5.31,9.66,7.46,14.74
   c0.92,2.16,1.95,4.26,3.16,6.27c1.09,1.82,2.69,2.73,4.81,2.78c5.38,0.12,10.61-0.72,15.76-2.28c4.16-1.26,8.26-2.69,12.31-4.28
   c3.16-1.25,5.22-3.65,6.96-6.4c1.9-3.02,2.95-6.43,4.27-9.72c1.21-3.02,2.58-5.96,4.91-8.3c2.4-2.4,5.31-4.01,8.46-5.19
   c1.15-0.42,2.32-0.83,3.54-1.28c-0.34-0.58-0.98-1.13-0.55-1.74c0.51-0.74,1.32-0.01,1.98-0.12c0.46-1.69,0.46-1.69,2.83-0.94
   c0.28-0.43,0.04-1.17,0.59-1.36c0.54-0.18,0.83,0.42,1.19,0.74c1.11-0.28,1.21-0.24,1.58,0.92c0.21,0.67,0.36,1.37,0.54,2.07
   c0.09-0.03,0.2-0.03,0.25-0.08c0.25-0.3,0.45-0.75,0.94-0.55c0.46,0.2,0.38,0.66,0.4,1.05c0,0.32-0.08,0.69,0.21,0.87
   c1.23,0.72,1.16,1.98,1.23,3.11c0.08,1.23,0.51,2.2,1.34,3.06c0.72,0.72,1.33,1.54,1.83,2.42C149.89,52.3,150.08,52.81,149.76,53.42
   z"
            />
            <g className={styles.light}>
                <path
                    d="M62.42,157.51v5.41h8.32v-5.41H68.5v-2.39h7.33v2.39h-2.21v14.36h2.21v2.39H68.5v-2.39h2.23v-6.56h-8.32v6.56
       h2.26v2.39h-7.31v-2.39h2.16v-14.36h-2.16v-2.39h7.31v2.39H62.42z"
                />
                <path
                    d="M84.26,157.51l4.89,13l4.74-13h-2.21v-2.39h7.3v2.39h-2.29l-6.32,16.95h-2.65l-6.42-16.95h-2.36v-2.39h7.54
       v2.39H84.26z"
                />
                <path d="M107.17,157.51v14.36h2.08v2.39h-7.05v-2.39h2.07v-14.36h-2.07v-2.39h7.05v2.39H107.17z" />
                <path
                    d="M129.39,155.12h6.63v2.39h-2.31v14.36h2.31v2.39h-7.2v-2.39h2.26v-14.36l-6.58,16.95h-0.57l-6.53-16.95v14.36
       h2.11v2.39h-6.79v-2.39h2.32v-14.36h-2.32v-2.39h6.56l5.04,13.1L129.39,155.12z"
                />
                <path
                    d="M149.44,156.32v-1.31h2.34v5.8h-2.34v-1.32c-0.99-1.49-2.34-2.23-4.06-2.23c-1.05,0-1.85,0.21-2.41,0.63
       c-0.56,0.42-0.84,1.03-0.84,1.83c0,0.76,0.26,1.34,0.79,1.74c0.53,0.4,1.34,0.74,2.43,1.02l2.25,0.6c1.43,0.38,2.53,0.88,3.29,1.51
       c0.77,0.63,1.26,1.29,1.5,1.99c0.23,0.7,0.35,1.49,0.35,2.35c0,1.73-0.54,3.11-1.62,4.13c-1.08,1.02-2.53,1.52-4.35,1.52
       c-1.73,0-3.31-0.63-4.74-1.9v1.59h-2.39v-6.97h2.39v1.69c1.14,2.1,2.63,3.15,4.47,3.15c1.08,0,1.91-0.28,2.5-0.83
       c0.59-0.55,0.89-1.33,0.89-2.34c0-1.37-0.96-2.32-2.89-2.86l-2.39-0.67c-1.72-0.49-2.99-1.16-3.81-1.99
       c-0.82-0.84-1.23-1.94-1.23-3.31c0-1.68,0.5-2.99,1.49-3.92c0.99-0.93,2.37-1.39,4.12-1.39
       C146.7,154.81,148.12,155.31,149.44,156.32z"
                />
            </g>
        </svg>
    );
};
