export type Response<T> =
    | {
          status: 200;
          success: true;
          result: T;
      }
    | {
          status: 401;
          success: false;
      }
    | {
          status: 404;
          success: false;
      };

export async function get<T>({
    url,
    data,
    formatResult,
    contentType,
}: {
    url: string;
    data?: Record<string, any>;
    formatResult?: (result: T) => T;
    contentType: 'application/json' | 'text/markdown';
}): Promise<Response<T>> {
    const parameters =
        data !== null && data !== undefined
            ? `?${Object.entries(data)
                  .map(([key, value]) => {
                      if (value === undefined) return undefined;

                      if (Array.isArray(value)) {
                          return value
                              .map((innerValue) => `${key}=${innerValue}`)
                              .join('&');
                      }

                      return `${key}=${value}`;
                  })
                  .filter(Boolean)
                  .join('&')}`
            : '';

    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${url}${parameters}`,
        {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: 'same-origin', // include, *same-origin, omit
            credentials: 'include',
            headers: {
                'Content-Type': contentType,
                Accept: contentType,
                // 'Content-Type': 'application/x-www-form-urlencoded',
                //'Content-Length': parameters?.length?.toString() || '0',
                //'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                URL: window.location.href,
            },
            //redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }
    ).catch((e) => {
        console.log(e);
        console.log('Can’t access ' + url + ' response. Blocked by browser?');

        // TODO: Report error

        return {
            status: 500,
            json: (): void => {
                return;
            },
            text: (): void => {
                return;
            },
        };
    });

    if (process.env.NODE_ENV === 'development') {
        console.log(response);
    }

    const status = response.status as Response<T>['status'];
    const success = status.toString().charAt(0) === '2';

    if (success) {
        const result: T =
            contentType === 'application/json'
                ? await response?.json()
                : await response?.text(); // parses JSON response into native JavaScript objects

        const formattedResult =
            typeof formatResult === 'function' ? formatResult(result) : result;

        return {
            status: status as 200,
            success,
            result: formattedResult,
        };
    }

    return {
        status: status as 404,
        success,
    };
}
