/* eslint-disable react/jsx-key */
import React, { ReactNode } from 'react';
import { useTable, UseTableOptions } from 'react-table';
import styles from './table.module.scss';

interface TableProps<Data extends object> extends UseTableOptions<Data> {
    isLoading?: boolean;
}

export function Table<Data extends object>({
    columns,
    data,
    isLoading,
}: TableProps<Data>) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data,
        });

    if (isLoading) {
        return <div>Loader...</div>;
    }

    // Render the UI for your table
    return (
        <table {...getTableProps()} className={styles.table}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                {column.render('Header') as ReactNode}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);

                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell') as ReactNode}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
