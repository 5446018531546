import { useEffect, useRef } from 'react';
import {
    Routes as RRDRoutes,
    Route,
    useLocation,
    Outlet,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IPage, IRouteProps } from 'typings';
import { Layout } from 'components/structure/layout';
import { Forside } from 'pages/forside';
import { useIsAuthenticated } from 'hooks/use-is-authenticated';
import { Login } from 'pages/login';
import { Tables } from 'pages/tables';
import { CreateOrder } from 'pages/create-order';
import { EditOrder } from 'pages/edit-order';

export type ValidLinks = 'forside' | 'oversigt' | 'opret';

const Wrapper = () => {
    const location = useLocation();
    const referrer = useRef<string>();
    const pathname = location.pathname || '';
    const paths = pathname.split('/').filter(Boolean);
    const controller = paths?.[0];

    const path: IRouteProps['path'] = {
        paths,
        pathname,
        controller,
    };

    const dispatch = useDispatch();

    const { isAuthenticated, isLoading, checkAuthentication } =
        useIsAuthenticated();

    useEffect(() => {
        //dispatch(clearAllTooltips());

        document.documentElement.scrollTop = 0;

        return () => {
            referrer.current = window.location.href;
        };
    }, [dispatch, path]);

    if (isLoading) return null;

    if (!isAuthenticated) return <Login onSuccess={checkAuthentication} />;

    return (
        <Layout path={path}>
            <Outlet />
        </Layout>
    );
};

export const Routes: IPage = () => (
    <RRDRoutes>
        <Route path="/" element={<Wrapper />}>
            <Route index={true} element={<Forside />} />
            <Route path="oversigt" element={<Tables />} />
            <Route path="opret" element={<CreateOrder />} />
            <Route path="ret/:orderId" element={<EditOrder />} />
            <Route path="*" element={null} />
        </Route>
    </RRDRoutes>
);
