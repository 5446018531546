/* eslint-disable @typescript-eslint/no-unused-vars */
import { api } from 'api';
import { Field, FieldArray, Form, Formik } from 'formik';
import { useGetApi } from 'hooks';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IPage } from 'typings';
import { sortByKey } from 'utils';
import { OrderProps, RaceForOrderProps } from './tables';

export interface RaceProps {
    id: number;
    name: string;
    short_name: string;
}

interface AddedRaceProps extends RaceProps {
    count: number;
    free: boolean;
}

interface FormValues {
    id: number;
    date: string;
    phone: string;
    name: string;
    sellerL: boolean;
    sellerM: boolean;
    sellerA: boolean;
    sellerFB: boolean;
    races: AddedRaceProps[];
    price: number;
    freeRaces: AddedRaceProps[];
    note: string;
    sent: boolean;
    age: string;
}

export const EditOrder: IPage = () => {
    const navigate = useNavigate();
    const orderId = useParams()?.orderId;

    const races = useGetApi<RaceProps[]>('races');
    //const order = useGetApi<RaceProps[]>(`order/${orderId}`);
    const racesForOrders = useGetApi<RaceForOrderProps[]>(
        'orders/races_for_orders'
    );
    const orders = useGetApi<OrderProps[]>('orders');

    const initialValues = useMemo((): FormValues | undefined => {
        if (
            !orderId ||
            races.isLoading ||
            orders.isLoading ||
            racesForOrders.isLoading ||
            !orders.response?.success ||
            !racesForOrders.response?.success ||
            !races.response?.success
        ) {
            return;
        }

        const parsedOrderId = parseInt(orderId);

        if (Number.isNaN(parsedOrderId)) return;

        const order = orders.response.result.find(
            ({ id }) => id === parsedOrderId
        );

        if (!order) return;

        const racesResult = races.response.result;

        const racesForThisOrder = racesForOrders.response.result
            .filter(({ order_id }) => order_id === parsedOrderId)
            .map((raceForOrder): AddedRaceProps => {
                const race = racesResult.find(
                    ({ id }) => id === raceForOrder.race_id
                );

                return {
                    ...raceForOrder,
                    name: race?.name ?? '',
                    short_name: race?.short_name ?? '',
                    id: race?.id ?? 0,
                };
            });

        return {
            ...order,
            date: order.timestamp,
            races: racesForThisOrder.filter(({ free }) => !free),
            freeRaces: racesForThisOrder.filter(({ free }) => free),
            sellerL: order.seller_l,
            sellerM: order.seller_m,
            sellerA: order.seller_a,
            sellerFB: order.seller_fb,
        };
    }, [orderId, races, racesForOrders, orders]);

    const sortedRaces = useMemo(() => {
        if (!races.response?.success) return [];

        return sortByKey(races.response.result, 'sorting');
    }, [races.response]);

    if (!initialValues) return null;

    const handleSubmit = (values: FormValues) => {
        const races = [
            ...values.races
                .filter(({ count }) => count)
                .map((race) => ({
                    id: 0,
                    order_id: 0,
                    race_id: parseInt(race.id.toString()),
                    count: parseInt(race.count.toString()),
                    free: false,
                })),
            ...values.freeRaces
                .filter(({ count }) => count)
                .map((race) => ({
                    id: 0,
                    order_id: 0,
                    race_id: parseInt(race.id.toString()),
                    count: parseInt(race.count.toString()),
                    free: true,
                })),
        ];

        api.put({
            url: 'order',
            data: {
                id: initialValues.id,
                timestamp: values.date,
                name: values.name,
                phone: values.phone,
                price: values.price,
                seller_l: values.sellerL,
                seller_m: values.sellerM,
                seller_a: values.sellerA,
                seller_fb: values.sellerFB,
                age: values.age,
                sent: values.sent,
                note: values.note,
                races,
            },
        }).then((result) => {
            if (!result.success || typeof result.result !== 'number') return;

            navigate('/oversigt');
        });
    };

    if (!races.response?.success) return null;

    return (
        <Formik<FormValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={({ values, dirty, handleChange, handleBlur }) => (
                <Form>
                    <h1>Opret bestilling</h1>
                    <label>Dato</label>
                    <Field name="date" type="date" />
                    <br />
                    <label>Telefonnummer</label>
                    <Field name="phone" type="text" />
                    <br />
                    <label>Navn</label>
                    <Field name="name" type="text" />
                    <br />
                    <label>Sælger</label>
                    <Field name="sellerL" type="checkbox" />
                    Louise
                    <br />
                    <Field name="sellerM" type="checkbox" />
                    Maria
                    <br />
                    <Field name="sellerA" type="checkbox" />
                    Andet
                    <br />
                    <Field name="sellerFB" type="checkbox" />
                    Facebook
                    <br />
                    <label>Racer</label>
                    <FieldArray
                        name="races"
                        render={(arrayHelpers) => (
                            <>
                                {values.races.map((race, index) => (
                                    <div key={index}>
                                        <Field
                                            name={`races.${index}.count`}
                                            type="number"
                                        />{' '}
                                        <select
                                            name={`races.${index}.id`}
                                            defaultValue={race.id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            {sortedRaces.map((sortedRace) => (
                                                <option
                                                    key={sortedRace.id}
                                                    value={sortedRace.id}
                                                >
                                                    {sortedRace.name}
                                                </option>
                                            ))}
                                        </select>{' '}
                                        <button
                                            type="button"
                                            onClick={() =>
                                                arrayHelpers.remove(index)
                                            }
                                        >
                                            Slet
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={() => {
                                        const raceToAdd =
                                            sortedRaces.find(
                                                (race) =>
                                                    !values.races.some(
                                                        (addedRace) =>
                                                            addedRace.id ===
                                                            race.id
                                                    )
                                            ) ?? sortedRaces[0];

                                        arrayHelpers.push({
                                            ...raceToAdd,
                                            count: 0,
                                        });
                                    }}
                                >
                                    Tilføj race
                                </button>
                            </>
                        )}
                    />
                    <br />
                    <label>Pris</label>
                    <Field name="price" type="number" />
                    <br />
                    <label>Gratis racer</label>
                    <FieldArray
                        name="freeRaces"
                        render={(arrayHelpers) => (
                            <>
                                {values.freeRaces.map((race, index) => (
                                    <div key={index}>
                                        <Field
                                            name={`freeRaces.${index}.count`}
                                            type="number"
                                        />{' '}
                                        <select
                                            name={`freeRaces.${index}.id`}
                                            defaultValue={race.id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            {sortedRaces.map((sortedRace) => (
                                                <option
                                                    key={sortedRace.id}
                                                    value={sortedRace.id}
                                                >
                                                    {sortedRace.name}
                                                </option>
                                            ))}
                                        </select>{' '}
                                        <button
                                            type="button"
                                            onClick={() =>
                                                arrayHelpers.remove(index)
                                            }
                                        >
                                            Slet
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={() => {
                                        const raceToAdd =
                                            sortedRaces.find(
                                                (race) =>
                                                    race.short_name === 'R'
                                            ) ?? sortedRaces[0];

                                        arrayHelpers.push({
                                            ...raceToAdd,
                                            count: 0,
                                        });
                                    }}
                                >
                                    Tilføj gratis race
                                </button>
                            </>
                        )}
                    />
                    <br />
                    <label>Bemærkning</label>
                    <Field name="note" type="text" />
                    <br />
                    <br />
                    <Field name="sent" type="checkbox" />
                    Sendt
                    <br />
                    <label>Alder</label>
                    <Field name="age" type="text" />
                    <br />
                    <br />
                    <button type="submit" disabled={!dirty}>
                        {values.age.trim()
                            ? 'Gem ændring til kylle bestilling'
                            : 'Gem ændring til rugeæg bestilling'}
                    </button>
                </Form>
            )}
        />
    );
};
