import { Formik, Field, Form } from 'formik';
import { api } from 'api';
import { FunctionComponent } from 'react';

interface IFormValues {
    user: string;
    password: string;
}

interface ILogin {
    onSuccess: () => void;
}

export const Login: FunctionComponent<ILogin> = ({ onSuccess }: ILogin) => {
    const handleSubmit = async (data: IFormValues) => {
        if (!data.user.trim() || !data.password.trim()) {
            return window.location.reload();
        }

        const response = await api.post({
            url: 'login',
            data,
        });

        if (response.success) return onSuccess();

        return window.location.reload();
    };

    return (
        <Formik
            initialValues={{ user: '', password: '' }}
            onSubmit={handleSubmit}
        >
            <Form>
                <Field name="user" type="text" placeholder="Username" />{' '}
                <Field name="password" type="password" placeholder="Password" />{' '}
                <button type="submit">Login</button>
            </Form>
        </Formik>
    );
};
