import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const stateScope = 'layout';

interface ILayoutState {
    visIndholdsfortegnelse: boolean;
}

const initialState: ILayoutState = {
    visIndholdsfortegnelse: false,
};

export const slice = createSlice({
    name: stateScope,
    initialState,
    reducers: {
        setVisIndholdsfortegnelse: (
            state,
            action: PayloadAction<boolean>
        ): void => {
            state.visIndholdsfortegnelse = action.payload;
        },
        visIndholdsfortegnelse: (state): void => {
            state.visIndholdsfortegnelse = true;
        },
        skjulIndholdsfortegnelse: (state): void => {
            state.visIndholdsfortegnelse = false;
        },
    },
});

export const { reducer, actions } = slice;

export const {
    setVisIndholdsfortegnelse,
    visIndholdsfortegnelse,
    skjulIndholdsfortegnelse,
} = actions;

export const getState = (state: RootState): ILayoutState => state[stateScope];

export const reduced = { [stateScope]: reducer };
